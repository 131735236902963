:root {
    color-scheme: light only !important;
    --color-mode: 'light' !important;
}

html, body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans','Helvetica', sans-serif;
    font-display: block;
    font-weight: 400;
    padding-top: 71px;
    color: #5e6c83;
}

a {
    color: #1b75bc;
}

a:active,a:hover, a:focus{
    text-decoration: none;
}

@media (min-width: 1280px) {
    .container {
        max-width: 1280px;
    }
}

@media (max-width: 768px) {
    .zopim {
        box-shadow: none;
        width: auto;
        height: auto;
    }
    .mobile-spacer{
        margin-top:10px;
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    .lg-100 {
        width: 100%;
    }
}

.cookie-container {
    position: fixed;
    bottom: -100%;
    left: 0;
    right: 0;
    background: #1b75bcd1;
    color: #f5f6fa;
    padding: 30px 30px;
    box-shadow: 0 -2px 16px rgba(47, 54, 64, 39);
    -webkit-transition: 400ms;
    transition: 400ms;
    display: block;
    margin: 0 auto;
    width: 100%;
}

.cookie-container.active {
    bottom: 0;
}
.cookies-text {
    font-size:16px;
    text-align:center;
}

.cookie-container a {
    color: #f5f6fa;
}
.cookies-check{
    font-size:14px;
    display: inline;
    padding-left:10px;
}
.cookie-btn {
    background: #45c28d;
    border: 0;
    color: #fff;
    padding: 12px 48px;
    font-size: 18px;
    margin-bottom: 16px;
    border-radius: 3px;
    font-family: 'Open Sans',sans-serif;
    cursor: pointer;
    margin-right: 10px;
}

.cookie-btn-rej{
    padding: 12px 48px;
    border: 0;
    border-radius: 3px;
    border: solid 1px #d8dde6;
    color:white;
    background-color: #ffffff2e;;
    margin-bottom: 16px;
    border-radius: 3px;
    font-family: 'Open Sans',sans-serif;
    cursor: pointer;
    margin-right: 10px;
}

.cookie-btn-privacy {
    background: #263857;
    border: 0;
    color: #fff;
    padding: 12px 48px;
    font-size: 18px;
    margin-bottom: 16px;
    border-radius: 3px;
    font-family: 'Open Sans',sans-serif;;
    cursor: pointer;

}
.cookie-btn,.cookie-btn-privacy{
    font-size: 16px;
}
@media only screen and (max-width: 768px) {

    .cookie-container {

        padding: 30px 30px;
        width: 100%;
  }
    .cookies-text{
        font-size:12px;
        text-align:left;
    }
    .cookie-btn,.cookie-btn-privacy,.cookie-btn-rej{
        font-size: 12px;
    }
    .cookie-btn, .cookie-btn-rej {
    margin-right: 0px;
    }

    }